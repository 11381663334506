import { useState, useEffect } from 'react';
import { Divider, alpha } from '@mui/material';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import { useTheme } from '@emotion/react';
import LocationCards from '../components/LocationCards';
import corporate_Headquarters from '../assets/pics/corporate_Headquarters.jpg';

import * as React from 'react';
import { styled } from '@mui/material/styles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import BusinessIcon from '@mui/icons-material/Business';
import AppAppBar from '../components/AppAppBar';
import Footer from '../components/Footer';
import SnackbarBase from '../components/SnackbarBase';
import { Fade } from 'react-awesome-reveal';

const primary_color = '#283B6C';
const secondary_color = '#4589BC';

const primary_color_dark = '#4161B0';
const secondary_color_dark = '#56A9E8';
const lots = [
    'Florida',
    'Texas',
    ' North Carolina',
    'South Carolina',
    'Alabama'
];

function Location({ mode, toggleColorMode }) {
    const { palette } = useTheme();
    const [locations, setLocations] = useState([
        {
            title: 'NCE corporate headquarters at Kennesaw',
            phone: `(833) 278-2663 or 2NCE.`,
            address: `125 Town Park Dr., Suite 300 Kennesaw, GA 30144`
        }
    ]);

    const [loading, setLoading] = useState(true);
    const [didError, setDidError] = useState(false);
    useEffect(() => {
        const handleGetLocations = async () => {
            setLoading(true);

            setLoading(true);
            try {
                const response = await fetch('/api/locations', {
                    method: 'get', // or 'PUT'
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                if (response.ok) {
                    const result = await response.json();

                    setLocations(result);
                    // setHtml(result[0].content);
                } else {
                    setDidError(true);
                }
            } catch (error) {
                setDidError(true);
                setLoading(false);
            }
            setLoading(false);
        };
        handleGetLocations();
        const timer = setTimeout(() => {
            setLoading(false);
        }, 600);
        return () => {
            clearTimeout(timer);
        };
    }, []);
    <SnackbarBase
        isOpen={didError}
        setIsOpen={setDidError}
        type={'error'}
        message={
            'there was a problem retrieving Locations. Please try again later'
        }
    />;

    return (
        <React.Fragment>
            <AppAppBar mode={mode} toggleColorMode={toggleColorMode} />
            <Fade>
                <Box sx={{ bgcolor: 'background.default' }}>
                    <Box
                        sx={theme => ({
                            width: '100%',
                            backgroundImage:
                                theme.palette.mode === 'light'
                                    ? 'linear-gradient(180deg, #CEE5FD, #FFF)'
                                    : `linear-gradient(#02294F, ${alpha(
                                          '#090E10',
                                          0.0
                                      )})`,
                            backgroundSize: '100% 20%',
                            backgroundRepeat: 'no-repeat',
                            pt: 15
                        })}>
                        <Box sx={{ p: 2 }}>
                            <Typography
                                component="h2"
                                variant="h4"
                                textAlign="center"
                                color={
                                    palette.mode === 'light'
                                        ? primary_color
                                        : primary_color_dark
                                }
                                sx={{
                                    fontSize: 'clamp(3rem, 10vw, 4rem)'
                                }}>
                                Our Locations
                            </Typography>
                            <LocationCards
                                direction={'left'}
                                id={'corporate_Headquarters'}
                                img={corporate_Headquarters}
                                items={locations}
                            />

                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center'
                                }}>
                                <Typography
                                    color={
                                        palette.mode === 'light'
                                            ? primary_color
                                            : primary_color_dark
                                    }
                                    sx={{
                                        mt: 4,
                                        mb: 2,
                                        fontSize: 25
                                    }}
                                    variant="h6"
                                    component="div">
                                    With more locations coming soon in:
                                </Typography>
                                <List>
                                    {lots.map(lot => {
                                        return (
                                            <ListItem>
                                                <ListItemIcon>
                                                    <BusinessIcon
                                                        sx={{
                                                            color:
                                                                'text.primary',
                                                            fontSize: 35
                                                        }}
                                                    />
                                                </ListItemIcon>
                                                <ListItemText primary={lot} />
                                            </ListItem>
                                        );
                                    })}
                                </List>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Divider />
                <Footer />
            </Fade>
        </React.Fragment>
    );
}

export default Location;
