import React, { useState } from 'react';
import { Button, Divider, TextField, alpha } from '@mui/material';
import Box from '@mui/material/Box';
import { Container, Grid, Paper } from '@mui/material';
import { Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useTheme } from '@emotion/react';
import EngineeringIcon from '@mui/icons-material/Engineering';
import { Link } from 'react-router-dom';
import AppAppBar from '../components/AppAppBar';
import Footer from '../components/Footer';
import { Fade } from 'react-awesome-reveal';

const primary_color = '#283B6C';
const secondary_color = '#4589BC';

const primary_color_dark = '#4161B0';
const secondary_color_dark = '#56A9E8';

function Delivery({ mode, toggleColorMode }) {
    const { palette } = useTheme();
    const [trackingNumber, setTrackingNumber] = useState('');
    const [trackingData, setTrackingData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [trackingNumberError, setTrackingNumberError] = useState(false);

    const handleTrack = async () => {
        if (!trackingNumber > 0) {
            setTrackingNumberError(true);
            return;
        }
        setTrackingNumberError(false);
        setLoading(true);
        try {
            //do some stuff here
            const response = await fetch(`/api/track/${trackingNumber}`);
            const data = await response.json();
            setTrackingData(data);
        } catch (error) {
            console.error('Error fetching tracking data:', error);
        } finally {
            setLoading(false);
        }
    };

    const DeliveryLocation = ({ location }) => {
        const {
            AddressLine1,
            AddressLine2,
            City,
            State,
            PostalCode
        } = location;
        return (
            <Typography
                textAlign="center"
                color="text.secondary"
                sx={{
                    alignSelf: 'center',
                    width: '100%'
                }}>
                Delivery address:{` ${AddressLine1} ${AddressLine2}, ${City}, ${State}, ${PostalCode}.`}
            </Typography>
        );
    };

    return (
        <React.Fragment>
            <AppAppBar mode={mode} toggleColorMode={toggleColorMode} />
            <Fade>
                <Box sx={{ bgcolor: 'background.default' }}>
                    <Box
                        sx={theme => ({
                            width: '100%',
                            backgroundImage:
                                theme.palette.mode === 'light'
                                    ? 'linear-gradient(180deg, #CEE5FD, #FFF)'
                                    : `linear-gradient(#02294F, ${alpha(
                                          '#090E10',
                                          0.0
                                      )})`,
                            backgroundSize: '100% 20%',
                            backgroundRepeat: 'no-repeat',
                            pt: 15,
                            minHeight: 800
                        })}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                pt: { xs: 2, sm: 10 }
                            }}>
                            <Box
                                sx={{
                                    width: '100%',
                                    maxWidth: {
                                        xs: 460,
                                        sm: 660,
                                        md: 760,
                                        lg: 860
                                    },
                                    p: 1,
                                    my: 4,
                                    bgcolor: 'background.paper',
                                    border: 1,
                                    borderRadius: 5,
                                    borderColor: theme => {
                                        if (theme.palette.mode === 'light') {
                                            return 'grey.200';
                                        }
                                        return 'grey.800';
                                    }
                                }}>
                                <Typography
                                    textAlign="center"
                                    style={{
                                        color:
                                            palette.mode === 'light'
                                                ? primary_color
                                                : primary_color_dark,

                                        fontSize: 24
                                    }}>
                                    Track your delivery
                                </Typography>
                                <Divider />

                                <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <Paper
                                                sx={{
                                                    p: 2,
                                                    display: 'flex',
                                                    flexDirection: 'column'
                                                }}>
                                                {
                                                    // <Typography
                                                    //     textAlign="center"
                                                    //     color="text.secondary"
                                                    //     sx={{
                                                    //         alignSelf: 'center',
                                                    //         width: {
                                                    //             sm: '100%',
                                                    //             md: '80%'
                                                    //         }
                                                    //     }}>
                                                    //     Please enter your tracking
                                                    //     number.
                                                    // </Typography>
                                                }

                                                <TextField
                                                    label="Tracking Number"
                                                    id="tracking-number"
                                                    placeholder="Enter your tracking number here"
                                                    defaultValue=""
                                                    error={trackingNumberError}
                                                    helperText={
                                                        trackingNumberError ===
                                                        true
                                                            ? 'please into your tracking number to continue'
                                                            : ''
                                                    }
                                                    value={trackingNumber}
                                                    onChange={e =>
                                                        setTrackingNumber(
                                                            e.target.value
                                                        )}
                                                    variant="standard"
                                                    sx={{
                                                        mt: 0,
                                                        pt: 0,
                                                        alignSelf: 'center',
                                                        width: '40%',
                                                        mb: 2
                                                    }}
                                                />
                                                <Button
                                                    variant="outlined"
                                                    onClick={handleTrack}
                                                    sx={{
                                                        mt: 0,
                                                        pt: 0,
                                                        alignSelf: 'center',
                                                        width: '20%',
                                                        mb: 2
                                                    }}>
                                                    Track
                                                </Button>
                                                <Divider />
                                                {loading &&
                                                    <CircularProgress />}

                                                {trackingData &&
                                                    <div>
                                                        {
                                                            //    deliveryLocation: {
                                                            //  CompanyName: 'Jimmy Mincey',
                                                            //  AddressLine1: '3760 Berdon Lane',
                                                            //  City: 'Decatur',
                                                            //  State: 'GA',
                                                            //  PostalCode: '30034'
                                                            //    }
                                                        }
                                                        <Typography
                                                            textAlign="center"
                                                            color="text.primary"
                                                            variant="h6">
                                                            Tracking Details
                                                        </Typography>
                                                        {/* Display tracking data here */}
                                                        <Box
                                                            sx={{
                                                                width: '100%',
                                                                alignSelf:
                                                                    'center',
                                                                alignContent:
                                                                    'center'
                                                            }}>
                                                                <DeliveryLocation location={trackingData.deliveryLocation}/>

                                                            <Typography
                                                                textAlign="center"
                                                                color="text.secondary"
                                                                sx={{
                                                                    alignSelf:
                                                                        'center',
                                                                    width:
                                                                        '100%'
                                                                }}>
                                                                Signature
                                                                required:{' '}
                                                                {trackingData.DeliverySignatureRequired === true ? 'Yes' : 'No'}
                                                            </Typography>
                                                            <Typography
                                                                textAlign="center"
                                                                color="text.secondary"
                                                                sx={{
                                                                    alignSelf:
                                                                        'center',
                                                                    width:
                                                                        '100%'
                                                                }}>
                                                                Last updated at:{' '}
                                                                {new Date(trackingData.status.Timestamp).toLocaleString()}
                                                            </Typography>
                                                            <Typography
                                                                textAlign="center"
                                                                color="text.secondary"
                                                                sx={{
                                                                    alignSelf:
                                                                        'center',
                                                                    width:
                                                                        '100%'
                                                                }}>
                                                                Estimated
                                                                delivery date:{' '}
                                                                {new Date(
                                                                    trackingData.DeliveryArrivalDate
                                                                ).toLocaleDateString()}
                                                            </Typography>
                                                            {trackingData.status
                                                                .Description
                                                                .length > 0
                                                                ? <Typography
                                                                      textAlign="center"
                                                                      color="text.secondary"
                                                                      sx={{
                                                                          alignSelf:
                                                                              'center',
                                                                          width:
                                                                              '100%'
                                                                      }}>
                                                                      Notes:{' '}
                                                                      {trackingData.status.Description}
                                                                  </Typography>
                                                                : null}
                                                            <Typography
                                                                textAlign="center"
                                                                color="text.secondary"
                                                                sx={{
                                                                    alignSelf:
                                                                        'center',
                                                                    width:
                                                                        '100%'
                                                                }}>
                                                                Delivery status:{' '}
                                                                {trackingData.status.Level}
                                                            </Typography>
                                                        </Box>
                                                    </div>}
                                            </Paper>
                                        </Grid>
                                    </Grid>
                                </Container>
                            </Box>
                        </Box>
                    </Box>
                </Box>

                <Divider />
                <Footer />
            </Fade>
        </React.Fragment>
    );
}

export default Delivery;
