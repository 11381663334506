import React from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {MenuItem, useTheme } from '@mui/material';
import GeneralModal from './dashboard/GeneralModal';

const primary_color = '#283B6C';

const primary_color_dark = '#4161B0';
const formFields = [
    {
        lable: 'Name',
        name: 'name',
        value: '',
        type: 'text',
        validText: 'please enter your name'
    },
    {
        lable: 'Phone Number',
        name: 'phone',
        value: '',
        type: 'phone_number',
        validText: 'please enter your phone number'
    },
    {
        lable: 'Email',
        name: 'email',
        value: '',
        type: 'email',
        validText: 'please enter a valid email'
    },
    {
        lable: 'Message',
        name: 'message',
        value: '',
        type: 'multiline_text',
        validText: 'please enter a description with at least 20 characters'
    }
];

export default function ContactModal({ buttonType = 'normal' }) {
    const { palette } = useTheme();

    return (
        <div>
            <GeneralModal
                IconButton={() => null}
                IconButtonEnd={() => null}
                formFields={formFields}
                title="Contact us"
                description="Please fill out this form and we will be in contact with you soon."
                handleSend={async data => {
                    console.log(data);
                        let url = '/api/inquire';
                        if (buttonType === 'appBar-quote') {
                            url = '/api/inquire/quote';
                        }
                        const response = await fetch(url, {
                            method: 'POST', // or 'PUT'
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify(data),

                        });
        

                         if (response.ok) {
                        const result = await response.json();
                        console.log('Success:', result);
                        return response;
                    }
                    if (!response.ok) {
                        return response;
                    }
                    
                }}
                useSub={true}
                SubBtn={({callback}) =>
                    <React.Fragment>
                        {buttonType === 'normal'
                            ? <Button onClick={callback} sx={{ my: 2 }}>
                                  <Typography
                                      id="modal-modal-title"
                                      variant="h4"
                                      component="h2"
                                      color={
                                          palette.mode === 'light'
                                              ? primary_color
                                              : primary_color_dark
                                      }>
                                      Contact us
                                  </Typography>
                              </Button>
                            : null}
                        {buttonType === 'appBar'
                            ? <MenuItem
                                  onClick={callback}
                                  sx={{ py: '6px', px: '12px' }}>
                                  <Typography
                                      variant="body2"
                                      color="text.primary">
                                      Contact us
                                  </Typography>
                              </MenuItem>
                            : null}
                        {buttonType === 'appBar-quote'
                            ? <MenuItem
                                  onClick={callback}
                                  sx={{ py: '6px', px: '12px' }}>
                                  <Typography
                                      variant="body2"
                                      color="text.primary">
                                      Contact Sales
                                  </Typography>
                              </MenuItem>
                            : null}
                    </React.Fragment>}
            />
        </div>
    );
}
